import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";

import { FormTitle, StyledAlert } from "../ui/StyledControls";

import { clearState, loadLassoState, saveState } from "../app/storage";
import { useAutoLogin } from "../hooks/useAutoLogin";
import { EMPTY_STATE } from "../models/LassoState";
import useSessionStorage from "../hooks/useSessionStorage";

export const ThankYouPage = ({
  navigator,
}: {
  navigator: (url: string) => void;
}) => {
  const [alertMessage, setAlert] = useState("");
  const [lassoSecret, setLassoSecret] = useState(EMPTY_STATE);
  const [userName, setUserName] = useState(lassoSecret.userName || "");

  const [, clearEmailPromptData] = useSessionStorage("emailData");
  const [, clearPreviousPageLoad] = useSessionStorage("previous_page_loads");

  const [triggerAutoLogin] = useAutoLogin(navigator, () => {});

  const clearAllCookies = (): void => {
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
  };

  const resetState = async () => {
    await saveState(EMPTY_STATE);
    setLassoSecret(EMPTY_STATE);
    setUserName(EMPTY_STATE.userName);
  };

  useEffect(() => {
    const clearBrowser = async () => {
      const state = await loadLassoState();

      const originalState = state;
      clearAllCookies();
      await clearState();
      await saveState({
        ...EMPTY_STATE,
        userName: originalState.userName,
        loginType: originalState.loginType,
        loginUrl: originalState.loginUrl,
      });
      setUserName(state.userName);
      clearEmailPromptData(undefined);
      clearPreviousPageLoad(undefined);

      if (state.userName === "") {
        // eslint-disable-next-line no-void
        void (async () => {
          await resetState().then(() => navigator("/"));
        })();
      }
    };
    // eslint-disable-next-line no-void
    void (async () => {
      await clearBrowser();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    triggerAutoLogin();
  };

  return (
    <Stack spacing={3}>
      <FormTitle>Thank You</FormTitle>
      <Stack>
        <label>Login again as {userName} </label>
        <Typography variant="caption">
          <a
            href="#"
            onClick={() => {
              // eslint-disable-next-line no-void
              void resetState().then(() => navigator("/"));
            }}
          >
            Not you?
          </a>
        </Typography>
      </Stack>

      <Button
        fullWidth
        onClick={submitHandler}
        type="submit"
        variant="contained"
      >
        Continue
      </Button>
      <div hidden={alertMessage === ""}>
        <StyledAlert severity="error" onClose={() => setAlert("")}>
          {alertMessage}
        </StyledAlert>
      </div>
    </Stack>
  );
};
