import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { EMPTY_STATE, PasswordFormData } from "../models/LassoState";
import {
  PromptForEmailResponse,
  LoginResponse,
  SuccessFullLoginResponse,
} from "../models/LoginResponse";
import { clearState, loadLassoState, saveState } from "../app/storage";
import { useFocusInput } from "../hooks/useDefaultFocusInput";
import { useLoginHandler } from "../hooks/useLogin";

export function isSuccessFullLoginResponse(
  response: LoginResponse
): response is SuccessFullLoginResponse {
  return typeof response === "object" && "landing" in response;
}

export function isPromptForEmail(
  response: LoginResponse
): response is PromptForEmailResponse {
  return (
    typeof response === "object" && "internal" in response && "data" in response
  );
}

export const PasswordEntryForm = ({
  navigator,
}: {
  navigator: (url: string | undefined) => void;
}) => {
  const [alertMessage, setAlert] = useState("");
  const [lassoState, setLassoState] = useState(EMPTY_STATE);
  const [formData, setFormData] = useState({} as PasswordFormData);
  const [showPassword, setShowPassword] = useState(false);
  const [showCancel, setCancel] = useState(true);
  const setInputRef = useFocusInput();

  const attemptLogin = useLoginHandler({
    navigator,
    lassoState,
    setAlert,
    setCancel,
  });

  useEffect(() => {
    // eslint-disable-next-line no-void
    void (async () => {
      try {
        const state = await loadLassoState();
        setLassoState(state);
        setFormData({
          password: "",
          userName: state.userName,
        } as PasswordFormData);
      } catch (error) {
        setFormData({
          password: "",
          userName: "",
        } as PasswordFormData);
      }
    })();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      password: event.target.value.trim(),
      userName: lassoState.userName,
    } as PasswordFormData);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const disableMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    attemptLogin({
      userName: formData.userName,
      password: formData.password,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  let helperText = "";
  if (alertMessage !== "") {
    helperText = alertMessage;
  } else if (formData.password === "") {
    helperText = "Password is required";
  }

  return (
    <form onSubmit={submitForm}>
      <Stack spacing={3}>
        <Stack>
          <TextField
            disabled
            fullWidth
            label="Username or Email"
            name="Username"
            size="small"
            InputLabelProps={{ shrink: Boolean(formData.userName) }}
            variant="outlined"
            onChange={handleChange}
            value={formData.userName}
          />
          <Typography variant="caption">
            <a
              href="#"
              onClick={() => {
                // eslint-disable-next-line no-void
                void (async () => {
                  await saveState({
                    ...lassoState,
                    autoLogin: false,
                  });
                  navigator("/");
                })();
              }}
            >
              Not you?
            </a>
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <TextField
            fullWidth
            autoFocus
            label="Password"
            inputRef={setInputRef()}
            name="password"
            size="small"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            required
            onChange={handleChange}
            error={formData.password === "" || alertMessage !== ""}
            helperText={helperText}
            InputProps={{
              autoComplete: "current-password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    onMouseDown={disableMouseDown}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Link
            href="#"
            variant="caption"
            onClick={() => navigator("/forgot-password")}
          >
            Forgot Password?
          </Link>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box flex={3}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={formData.password === ""}
            >
              Continue
            </Button>
          </Box>
          <Box flex={1}>
            <Button
              fullWidth
              type="reset"
              variant="contained"
              disabled={!showCancel}
              onClick={() => navigator("/")}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};
