import React, { useState, useEffect } from "react";
import { attemptConversion, isJson } from "../proxies/LoginProxy";
import { Box, Button, CircularProgress, Container, Stack } from "@mui/material";
import useSessionStorage from "../hooks/useSessionStorage";
import { red } from "@mui/material/colors";
import { useLoginHandler } from "../hooks/useLogin";
import { EMPTY_STATE } from "../models/LassoState";
import { loadLassoState } from "../app/storage";

export const WaitForConversion = ({
  navigator,
}: {
  navigator: (url: string | undefined) => void;
}) => {
  const [lassoState, setLassoState] = useState(EMPTY_STATE);
  const [emailData, clearEmailPromptData] = useSessionStorage("emailData");
  const [status, setStatus] = useState<"loading" | "success" | "failure">(
    "loading"
  );
  const [header, setHeader] = useState<string>("");
  const [message, setAlert] = useState<string>("");
  const [showCancel, setCancel] = useState<boolean>(false);

  const showProgress = () => {
    return (
      <Container style={{ textAlign: "center", padding: "2dvw" }}>
        <CircularProgress variant="indeterminate" />
      </Container>
    );
  };

  const showSuccess = () => {
    return <div>Press Continue to re-login with Nexus login</div>;
  };

  const showFailure = () => {
    return (
      <div>
        <p>Unable to convert your user to single signon</p>
        <span style={{ color: red.A400 }}>{message}</span>
        <p>Please contact support with your username and the above message</p>
        <p>
          Pressing <b>Continue</b> will continue to the Lasso CRM landing page,
          but you will continue to see this error until the conversion issue is
          resolved
        </p>
      </div>
    );
  };

  const attemptLogin = useLoginHandler({
    navigator,
    lassoState,
    setAlert,
    setCancel,
  });

  useEffect(() => {
    // eslint-disable-next-line no-void
    const loadState = async () => {
      const state = await loadLassoState();
      setLassoState(state);
    };
    // noinspection JSIgnoredPromiseFromCall
    loadState();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulate async call to backend
        const response = await attemptConversion({
          value: emailData as string,
        });
        if (response.ok) {
          await response.json();
          setHeader("Nexus Conversion Completed Successfully");
          setStatus("success");
        } else {
          if (isJson(response)) {
            response
              .json()
              .then((data) => {
                throw Error(data.message);
              })
              .catch((e: Error) => {
                throw Error(e.message);
              });
          } else {
            response
              .text()
              .then((data: string) => {
                throw Error(data);
              })
              .catch((e: Error) => {
                throw Error(e.message);
              });
          }
        }
      } catch (error) {
        setHeader("Nexus Conversion Failed");
        setStatus("failure");
      }
    };

    fetchData();
  }, []);

  // TODO if the conversion failed, we need to send the emaildata to the server and handle the login data
  function onContinue() {
    if (status === "success") {
      navigator("/");
    } else {
      // TODO change this to use the useLoginHandler with the emaildata parameter
      // TODO need to clear the EmailPromptData before doing the login
      const loginData: string = emailData as string;
      clearEmailPromptData(undefined);
      attemptLogin(loginData);
    }
  }

  let contents: React.ReactNode;
  switch (status) {
    case "success":
      contents = showSuccess();
      break;
    case "failure":
      contents = showFailure();
      break;
    default:
      contents = showProgress();
  }

  return (
    <Stack>
      <h4 style={{ fontSize: "1rem" }}>{header}</h4>
      <Stack>{contents}</Stack>
      <Stack direction="row" spacing={2}>
        <Box flex={3}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={status === "loading"}
            onClick={onContinue}
          >
            Continue
          </Button>
        </Box>
        <Box flex={1}>
          <Button
            fullWidth
            type="reset"
            variant="contained"
            disabled={!showCancel}
            onClick={() => navigator("/")}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
