import { login2, readResponse } from "../proxies/LoginProxy";
import {
  DEFAULT_EMAIL_PASSWORD_REQUEST,
  LoginRequestData,
} from "../models/PostRequests";
import { LoginResponse } from "../models/LoginResponse";
import { clearState, saveState } from "../app/storage";
import {
  isPromptForEmail,
  isSuccessFullLoginResponse,
} from "../pages/PasswordEntryForm";
import { LassoState } from "../models/LassoState";
import useSessionStorage from "./useSessionStorage";

export type UseLoginHandlerProp = {
  navigator: (url: string | undefined) => void;
  lassoState: LassoState;
  setAlert: (msg: string) => void;
  setCancel: (value: ((prevState: boolean) => boolean) | boolean) => void;
};

export function useLoginHandler(props: UseLoginHandlerProp) {
  const [, setEmailPromptData] = useSessionStorage(
    "emailData",
    DEFAULT_EMAIL_PASSWORD_REQUEST
  );

  const { navigator, lassoState, setAlert, setCancel } = props;

  return (loginData: LoginRequestData | string) => {
    login2(loginData)
      .then((result) => {
        if (!result.redirected) {
          readResponse<LoginResponse>(result)
            .then(async (data) => {
              if (isSuccessFullLoginResponse(data)) {
                await saveState({
                  ...lassoState,
                  autoLogin: true,
                });
                navigator("/landing");
              } else if (isPromptForEmail(data)) {
                setEmailPromptData(data.data);
                navigator(data.internal);
              } else {
                setAlert(data.toString());
              }
            })
            .catch((e: Error) => {
              setAlert(e.message || String(e));
              setCancel(true);
            });
        } else {
          const location = result.url;
          window.location.replace(location);
        }
      })
      .catch(async (e: Error) => {
        setAlert(e.message || e.toString());
        await clearState();
      });
  };
}
