import React, { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
} from "@mui/material";

import { EMPTY_STATE } from "../models/LassoState";
import { loadLassoState, saveState } from "../app/storage";
import { useAutoLogin } from "../hooks/useAutoLogin";
import { useLoginRouter } from "../hooks/useLoginRouter";
import { useFocusInput } from "../hooks/useDefaultFocusInput";

export const UserEntryForm = ({
  navigator,
}: {
  navigator: (url: string) => void;
}) => {
  const [alertMessage, setAlert] = useState("");
  const [lassoSecret, setLassoSecret] = useState(EMPTY_STATE);
  const [isLoading, setIsLoading] = useState(true);
  const setInputRef = useFocusInput({ shouldFocus: () => !isLoading });

  const resetState = async (userName: string) => {
    const newSecret = {
      ...EMPTY_STATE,
      userName,
    };
    await saveState(newSecret);
    setLassoSecret(newSecret);
    setIsLoading(false);
  };

  const onLoaded = () => {
    setIsLoading(false);
  };

  const [triggerAutoLogin] = useAutoLogin(navigator, onLoaded);
  const [loginRouter] = useLoginRouter(navigator, onLoaded, setAlert);

  const handleLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    loginRouter(lassoSecret.userName);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line
    void (async () => {
      await resetState(event.target.value.trim());
    })();
  };

  useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line
    void (async () => {
      loadLassoState()
        .then((secret) => setLassoSecret(secret))
        .then(() => {
          if (
            lassoSecret.autoLogin &&
            alertMessage === "" &&
            lassoSecret.userName !== ""
          ) {
            try {
              triggerAutoLogin();
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
          setIsLoading(false);
        })
        .catch((reason: Error) => {
          // eslint-disable-next-line no-console
          console.log(`Error occurred: ${reason.message}`);
          setIsLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setInputRef();
    }
  }, [isLoading, setInputRef]);

  if (isLoading) {
    return (
      <Container style={{ textAlign: "center", padding: "2dvw" }}>
        <CircularProgress variant="indeterminate" />
      </Container>
    );
  }

  let helperText = "";
  if (alertMessage !== "") {
    helperText = alertMessage;
  } else if (lassoSecret.userName === "") {
    helperText = "Username/email is required";
  }
  return (
    <form>
      <Stack spacing={3}>
        <TextField
          fullWidth
          inputRef={isLoading ? null : setInputRef}
          label="Username or Email"
          name="userName"
          size="small"
          variant="outlined"
          required
          autoFocus
          onChange={handleChange}
          value={lassoSecret.userName}
          error={lassoSecret.userName === "" || alertMessage !== ""}
          helperText={helperText}
          InputProps={{
            autoComplete: "username",
          }}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={lassoSecret.userName === ""}
          onClick={handleLogin}
        >
          Continue
        </Button>
      </Stack>
    </form>
  );
};
